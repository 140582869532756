import React, { useEffect, useState } from "react";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Mesh } from "three";

export function Car() {
  const [carIndex, setCarIndex] = useState(0);

  const carModels = [
    "car/scene.gltf",
    "car1/scene.gltf",
    "car2/scene.gltf",
    "car3/scene.gltf",
    "car4/scene.gltf",
  ];

  const gltf = useLoader(
    GLTFLoader,
    "https://car-show-threejs.vercel.app/" + "models/" + carModels[carIndex]
  );

  useEffect(() => {
    gltf.scene.position.set(0, -0.035, 0);
    gltf.scene.traverse((object) => {
      if (object instanceof Mesh) {
        object.castShadow = true;
        object.receiveShadow = true;
        object.material.envMapIntensity = 20;
      }
    });
  }, [gltf, carIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCarIndex((prevIndex) => {
        // If the current index is the last model, loop back to the first model
        if (prevIndex === carModels.length - 1) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 5000); // Switch cars every 10 seconds

    return () => clearInterval(interval);
  }, [carModels.length]);

  return <primitive object={gltf.scene} />;
}
