import React, { Suspense, useState, useEffect, useRef } from "react";
import { Canvas, useThree, useLoader } from "@react-three/fiber";
import paths from './paths/paths'
import {
  EffectComposer,
  DepthOfField,
  Bloom,
  ChromaticAberration,
} from "@react-three/postprocessing";
import * as THREE from "three";
import { BlendFunction } from "postprocessing";
import {
  CubeCamera,
  Environment,
  OrbitControls,
  PerspectiveCamera,
  Text,
} from "@react-three/drei";
import { Boxes } from "./Boxes";
import { Car } from "./Car";
import { Home } from "./Home";
import { Ground } from "./Ground";
import { Sec } from "./Sec";
import { FloatingGrid } from "./FloatingGrid";
import { Rings } from "./Rings";
import {BrowserRouter, Routes, Route, useNavigate, Navigate, Outlet} from "react-router-dom";

function CarShow() {


  const [text, setText] = useState("");

  const message = `Hey Happy Birthday! I'm glad you are as old as me now!
  \n You are invited to a free show. I hope you enjoy it. 
  \n Please like and subscribe to receive more free shows in the future`;

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < message.length) {
        setText((prevText) => prevText + message[index]);
        index++;
      } else {
        clearInterval(interval);
      }
    }, 200); // Adjust the interval time as needed
    return () => clearInterval(interval);
  }, []);

  function Sound({ url }) {
    const sound = useRef();
    const { camera } = useThree();
    const [listener] = useState(() => new THREE.AudioListener());
    camera.add(listener);
    const buffer = useLoader(THREE.AudioLoader, url);
    useEffect(() => {
      sound.current.setBuffer(buffer);
      sound.current.setRefDistance(1);
      sound.current.setLoop(true);
      sound.current.play();
      return () => camera.remove(listener);
    }, []);
    return <positionalAudio ref={sound} args={[listener]} />;
  }

  return (
    <>
      <OrbitControls target={[0, 0.35, 0]} maxPolarAngle={1.45} />

      <PerspectiveCamera makeDefault fov={50} position={[3, 2, 5]} />

      <color args={[0, 0, 0]} attach="background" />

      <Text
        position={[-1, 2, -5]}
        fontSize={0.5}
        color="white" // Change to your desired color
        anchorX="center"
        anchorY="middle"
      >
        {text}
      </Text>

      <CubeCamera resolution={256} frames={Infinity}>
        {(texture) => (
          <>
            <Environment map={texture} />
            <Car />
          </>
        )}
      </CubeCamera>

      <spotLight
        color={[1, 0.25, 0.7]}
        intensity={1.5}
        angle={0.6}
        penumbra={0.5}
        position={[5, 5, 0]}
        castShadow
        shadow-bias={-0.0001}
      />
      <spotLight
        color={[0.14, 0.5, 1]}
        intensity={2}
        angle={0.6}
        penumbra={0.5}
        position={[-5, 5, 0]}
        castShadow
        shadow-bias={-0.0001}
      />
      <Ground />
      <FloatingGrid />
      <Boxes />
      <Rings />
      <Sound url="/music.mp3" />

      <EffectComposer>
        {/* <DepthOfField focusDistance={0.0035} focalLength={0.01} bokehScale={3} height={480} /> */}
        <Bloom
          blendFunction={BlendFunction.ADD}
          intensity={1.3} // The bloom intensity.
          width={300} // render width
          height={300} // render height
          kernelSize={5} // blur kernel size
          luminanceThreshold={0.15} // luminance threshold. Raise this value to mask out darker elements in the scene.
          luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
        />
        <ChromaticAberration
          blendFunction={BlendFunction.NORMAL} // blend mode
          offset={[0.0005, 0.0012]} // color offset
        />
      </EffectComposer>
    </>
  );
}

function App() {
  const [unauthorize, setUnauthorize] = useState(true)



  return (
    <BrowserRouter>
 <Routes>
        <Route path={paths.CAR} element={<CarShow />} />
        <Route
          path={paths.HOME}
          element={<Home setUnauthorize={setUnauthorize} />}
        />
        {!unauthorize && <Route path={paths.SEC} element={<Sec 
        setUnauthorize={setUnauthorize}
        unauthorize={unauthorize}/>} />}
      </Routes>
  </BrowserRouter>
  );
}

export default App;
