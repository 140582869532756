import React, { useRef, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom'

export function Home({setUnauthorize}) {
    const [contentValue, setContentValue] = useState("")

    const handleChangeContent = (e) => {
        setContentValue(e.target.value)
    }
    const navigate = useNavigate()

    const handlePass = (e)=>{
        e.preventDefault()
if (contentValue === process.env.REACT_APP_SECRET){
          navigate("/sec")
          setUnauthorize(false)
}
    }

  return (
    <>
      <p className="text-2xl ml-10 font-bold">Enter password here</p>
      <form onSubmit={handlePass}>
        <input
        className="border border-gray-300 rounded-lg p-2 m-6"
        type="text"
        onChange={handleChangeContent}
        value={contentValue}
        placeholder='Enter password'
        />
        <button 
        className='p-2 rounded-lg border text-black border-gray-300 m-2'
        type="submit">
            Submit
        </button>
        </form>
    </>
  );
}