import React, { useRef, useState, useEffect } from "react";
import axios from 'axios';

const url =`${process.env.REACT_APP_SERVER_URL}/api/v1/todos`;

export function Sec({ unauthorize, setUnauthorize }) {
    const [contentValue, setContentValue] = useState("");
    const [todos, setTodos] = useState([]);
    
    useEffect(() => {
        fetchTodos();
    }, []);

    const fetchTodos = async () => {
        try {
            const { data } = await axios.get(url);
            setTodos(data.todos);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeContent = (e) => {
        setContentValue(e.target.value);
    };

    const handleAdd = async (e) => {
        e.preventDefault();

        try {
            const { data } = await axios.post(url, {
                title: contentValue,
                content: "Hello"
            });
            if (data){
              setTodos(prev => [...prev, data.todo]);
            }
    setContentValue("")
            fetchTodos();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Set a timeout to reset unauthorize state after 30 minutes
        const timeout = setTimeout(() => {
            setUnauthorize(true);
            // Navigate back to the home route
            window.location.href = "/";
        }, 30 * 60 * 1000); // 30 minutes in milliseconds
  
        // Clear the timeout when the component unmounts or unauthorize changes
        return () => clearTimeout(timeout);
    }, [unauthorize]);

    return (
        <div className="p-4 flex flex-col items-center justify-center min-h-screen bg-stone-900 text-white">
        <p className="text-2xl font-bold mb-6">Welcome!!!</p>
        <form onSubmit={handleAdd} className="mb-6 w-full max-w-lg">
            <textarea
                rows="4"
                className="border text-black border-gray-300 rounded-lg p-2 w-full mb-4"
                type="text"
                onChange={handleChangeContent}
                value={contentValue}
                placeholder='Enter text'
            />
            <button 
                className="p-2 rounded-lg border text-black border-black bg-red-300 hover:bg-blue-600 w-full"
                type="submit"
            >
                Add
            </button>
        </form>
        <div className="w-full max-w-lg">
        {todos.map((todo) => (
                    <div key={todo._id} className="mb-3">
                        <a href={`${todo.title}`} className="text-lg break-words">{todo.title}</a>
                    </div>
                ))}
        </div>
    </div>
    );
}
